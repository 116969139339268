import React from 'react'
import { Link } from "gatsby"
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Helmet from 'react-helmet'
import { Container, Row, Col, Button, Form } from 'reactstrap'
import styled from 'styled-components'
import Scroll from 'react-scroll'
import HiringSection from '../../components/Hiring'
import ContactForm from '../../components/ContactForm'
import WhatWeDoNav from '../../components/WhatWeDoNav'
import Faq from '../../components/Faq'
import Layout from '../../components/Layout'

const LogoRow = styled(Row)`
  font-size: 72px; margin-bottom: 20px;
  @media(max-width: 425px) {
    font-size: 45px;
  }
`

export default class extends React.Component {

  render() {
    return (
      <Layout>
      <div className="what-we-do">
        <Helmet
      title="Web & Mobile Development | Bishop Software"
      meta={[
        { name: 'description', content: 'High-quality custom web and mobile development services. Our programmers and designers can develop custom solutions to fit your needs and requirements with effective and appealing interfaces.' },
        { name: 'keywords', content: 'software,development,web,design,mobile,consultancy,consulting,website,application,react,ios,android' }
      ]}
    />
        <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, #2c3532 0%, #568c6c 73%, #78c498 90%, #edf5e0 100%)', color: '#fafafa'}}>
          <Header dropdownGradient='linear-gradient(#7ac99a 0px, #389583 100%)' section="what-we-do" />
          <Container className="text-center" style={{paddingTop: '1.875rem'}}>
            <div style={{margin: '55px 0 0 0'}}>
              <h1 style={{color: '#FFCB9B', fontSize: '40px', fontWeight: 300}}>Web &amp; Mobile Development</h1>
              <h3 style={{fontSize: '18px', marginTop: '35px', fontWeight: 400, lineHeight: '24px'}}>High-quality custom web development service, on demand at an affordable price.</h3>
              <div style={{marginTop: '35px', paddingBottom: '35px'}}>
                <Scroll.Link to="contact" spy={true} smooth={true} className="round btn btn-primary"><i className="fas fa-arrow-down"></i></Scroll.Link>
              </div>
            </div>
          </Container>
        </section>
      <Scroll.Element name="contact" />

        <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #ddd 0, #fff 30%, #fff 90%, #f1f1f1 100%)'}}>
      <Container>
        <Row>
          <Col md="8" xs="12">
            <p style={{color: '#555', fontSize: '22px'}}>Our team does front end and back end development for custom web applications, particularly those that require complex algorithms or rely heavily on data. Our programmers and designers can develop custom solutions to fit your needs and requirements with effective and appealing interfaces.</p>
            <p style={{color: '#555', fontSize: '18px'}}>We use the web as a platform to build applications that provide accessibility from multiple users and platforms without requiring reprogramming, recompiling, or even installation. We develop responsive sites that allow optimization of layout and interface with any form factor (full desktop to a small smart phone), and can help you understand your client base by including analytics data collection for valuable insight on how your web application is used.</p>
          </Col>
          <Col md="4" xs="12" className="d-none d-md-block">

            <WhatWeDoNav title="Related Services" titleBackground="#dbe5d0" titleColor="#333" color="#288D91" links={[
              { link: '/custom-software-development', title: 'Custom Software Development'},
              { link: '/software-consulting', title: 'Software Consulting'},
              { link: '/user-experience', title: 'User Experience & Design'}
            ]} />

          </Col>
        </Row>
      </Container>
    </section>

    <section style={{backgroundImage: 'linear-gradient(179deg, #2c2f39 2%, #363944 14%, #32353d 100%)', color: '#ccc', padding: '70px 0'}}>
      <Container className="text-center">
        <h1 style={{color: '#fff', marginBottom: '25px'}}>Why Web Apps Are Winning Over Desktop</h1>
          <Faq title="Accessibility" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee" open={true}>
          Web applications can be accessed from anywhere in the world. Remote and distributed teams are quickly becoming more popular thus increasing the importance of having global reach with software.
          </Faq>
          <Faq title="Rapid Development" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee">
          Many web applications look and feel the same, and for a good reason. Users want and expect certain behavior based on their experience with other web applications. At Bishop Software, we use tools and frameworks created by the open-source community to replicate standard and expected behaviors which allows us to rapidly kick-off new projects.
          </Faq>
          <Faq title="Cross-Platform" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee">
          Traditional desktop applications target only Windows, and likely only a select few of Windows versions (XP, 7, 8, and 10). Web applications are not bound by these limitations, and can be used on Windows, Mac, Linux, and mobile. Additionally, web based mobile apps are becoming an increasingly popular mechanism to support mobile devices without building multiple applications for each of the mobile platforms (iOS, Android, and Windows).
          </Faq>
          <Faq title="Minimal Install" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee">
          A browser is the only piece of software the user will need to install, and it would be difficult to find a modern PC, Mac, or mobile device that doesn’t already have one installed. This is vital if you are producing an application for users outside of your organization; the average user does not want to download and install applications on their devices.
          </Faq>
          <Faq title="Simplified Maintenance" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee">
          Updates to desktop applications require re-installation on all machines that run the software. In a 1,000 person organization it can become quite tedious and expensive. Web applications only need to be updated on the servers that host the application.
          </Faq>
          <Faq title="Reduced Costs" iconColorOpen="#54886A" iconColor="#a7d3b9" titleColor="#eee" titleColorOpen="#ccc" color="#eee" last={true}>
          The advantages listed above all lead to overall lower costs of development and on-going maintenance of web applications over traditional desktop software.
          </Faq>
      </Container>
    </section>

    <section style={{padding: '70px 0', backgroundImage: 'linear-gradient(132deg, #2c3532 0%, #568c6c 73%, #78c498 90%, #edf5e0 100%)', color: '#fafafa'}}>
          <Container className="text-center">
            <Row>
              <Col style={{fontSize: '18px'}}>
              <h1 style={{color: '#fff', marginBottom: '25px'}}>Software Modernization</h1>
              <p>Bishop Software recommends modern best practices and we follow the trends in <Link to="/user-experience" style={{color: '#fff', textDecoration: 'underline'}}>user-experience and user interface (UXUI)</Link> defined by other web application companies. We design all web applications to be able to be deployed to cloud providers such as Amazon Web Services (AWS) and Azure; this gives our clients a great deal of flexibility and long-term growth.</p>
              <p>Scalability is an important factor when designing web apps as it is very difficult to estimate how many users will use the application and the frequency at which they will use it. To remedy this, part of our recommend process includes stress and load testing to validate that the application will work not only for current needs but also for the expected usage 3 years from now.</p>
              </Col>
            </Row>
          </Container>
        </section>

    <section style={{textAlign: 'center', padding: '70px 0', backgroundImage: 'linear-gradient(132deg, rgba(3,57,115,1) 0%, rgba(44,136,148,1) 73%, rgba(26,174,196,1) 90%, rgba(33,225,255,1) 100%)', color: '#fafafa'}}>
      <Container>
        <LogoRow>
          <Col md="3">
            <i className="fab fa-node-js" style={{color: '#ffc107'}} />
          </Col>
          <Col md="3">
            <i className="icon-reactjs" style={{color: '#ffc107'}} />
          </Col>
          <Col md="3">
            <i className="icon-angular" style={{color: '#ffc107'}} />
          </Col>
          <Col md="3">
            <i className="icon-html5" style={{color: '#ffc107'}} />
          </Col>
        </LogoRow>
        <Row style={{marginBottom: '15px'}}>
          <Col>
          <h1 style={{color: '#fff'}}>Our Capabilities</h1>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <h3 style={{color: '#ffc107'}}>Microsoft</h3>
            <ul style={{listStyle: 'none', padding: 0, fontSize: '18px'}}>
              <li>.NET Core</li>
              <li>IIS</li>
              <li>ASP.NET / ASP.NET MVC</li>
              <li>Entity Framework</li>
              <li>SignalR</li>
              <li>SQL Server</li>
            </ul>
          </Col>
          <Col xs="12" md="6">
            <h3 style={{color: '#ffc107'}}>Javascript</h3>
            <ul style={{listStyle: 'none', padding: 0, fontSize: '18px'}}>
              <li>Node.js</li>
              <li>React / Angular / Vue.js</li>
              <li>Redux / Flux / Vuex</li>
              <li>MongoDB</li>
              <li>GraphQL</li>
              <li>WebSockets</li>
              <li>jQuery</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="6">
            <h3 style={{color: '#ffc107'}}>LAMP</h3>
            <ul style={{listStyle: 'none', padding: 0, fontSize: '18px'}}>
              <li>PHP</li>
              <li>Apache</li>
              <li>MySQL</li>
              <li>Wordpress</li>
              <li>Laravel / Symfony / Yii</li>
            </ul>
          </Col>
          <Col xs="12" md="6">
            <h3 style={{color: '#ffc107'}}>Other</h3>
            <ul style={{listStyle: 'none', padding: 0, fontSize: '18px'}}>
              <li>HTML / CSS</li>
              <li>Python - Django</li>
              <li>Rails</li>
              <li>Java - Vert.x, Dropwizard</li>
              <li>APIs (facebook, salesforce, etc.)</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>

    <section style={{padding: '70px 0', display: 'block', backgroundImage: 'linear-gradient(176deg, #ddd 0, #fff 30%, #fff 90%, #f1f1f1 100%)'}}>
      <ContactForm />
    </section>

        <HiringSection />

        <Footer section="what-we-do" />
      </div>
  </Layout>
    )
  }
}
  